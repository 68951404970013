import { useState } from "react";
import Loading from "../Loading";

const Requests = () => {
    const [loading, setLoading] = useState(true);

    const hideSpinner = () => {
        setLoading(false);
    };

    return (
        <>
            {loading && <Loading />}
            <iframe title="All requests"
                src={`${process.env.REACT_APP_REQUESTS_URL}`}
                onLoad={hideSpinner}
                hidden={loading ? true : false}
            >
            </iframe>
        </>
    )
}

export default Requests;