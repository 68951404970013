import './style.css';

const Main = () => {
    return (
        <div className="container">
            <div className="card">
                <div className="img-container">
                    <img src="/view.webp" alt="Vaccation" />
                </div>
                <div className="content">
                    <p className="p1">SERVICE REQUEST PORTAL </p>
                    <p className="p3">Service Request Portal improves communication between internal departments and our customers by connecting them using pre-set forms that allow for more customer choices and options. Internal Sunwing departments can quickly and efficiently respond to customers and track requests, all from SRP. </p>

                </div>
            </div>
        </div>
    )
}

export default Main;