import { useState } from "react";
import Loading from "../Loading";
import { useParams } from "react-router";

const RequestDetails = () => {
    const {id } = useParams<{ id: string}>();
    const [loading, setLoading] = useState(true);

    const hideSpinner = () => {
        setLoading(false);
    };

    return (
        <>
            {loading && <Loading />}
            <iframe title="SRP Sales Centre Request Details"
                src={`${process.env.REACT_APP_REQUEST_DETAILS_URL}?CaseID=${id}`}
                onLoad={hideSpinner}
                hidden={loading ? true : false}
            >
            </iframe>
        </>
    )
}

export default RequestDetails;