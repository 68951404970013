import { useState } from "react";
import Loading from "../Loading";
import { useParams } from "react-router";

const AgentPortalRequest = () => {
    const {tfa_434, tfa_493 } = useParams<{ tfa_434: string, tfa_493: string}>();
    const [loading, setLoading] = useState(true);

    const hideSpinner = () => {
        setLoading(false);
    };

    return (
        <>
            {loading && <Loading />}
            <iframe title="SRP Sales Centre Requests"
                src={`${process.env.REACT_APP_AGENT_PORTAL_REQUEST_URL}?tfa_434=${tfa_434}&tfa_493=${tfa_493}`}
                onLoad={hideSpinner}
                hidden={loading ? true : false}
            >
            </iframe>
        </>
    )
}

export default AgentPortalRequest;