import { Link, useLocation } from 'react-router-dom';
import './style.css';
import { useOktaAuth } from "@okta/okta-react";

const Header = () => {

  const location = useLocation();
  const { authState, oktaAuth } = useOktaAuth();
  const userName = authState?.idToken?.claims['name'];
  const groups = authState?.idToken?.claims['srpGroups'];

  return (
    <div className="topnav">
      <div className="nav">
        <input type="checkbox" id="item-toggle" hidden />
        <div className="nav-left">
          <div className="logo-burger">
              <Link className="logos" to="/">
                <img src="/sunwinglogo.png" alt="Sunwing-logo" />
                <img src="/vacances-westjet-quebec-logo-en.svg" alt="Westjet-logo" />
              </Link>
            <span className="burger"><label htmlFor="item-toggle">&#9776;</label></span>
          </div>
          {groups?.includes(process.env.REACT_APP_SRP_OKTA_SALES_CENTER_GROUP) && <a className="item" href="/srpsalescentre">Submit a new request<br />{location.pathname === "/srpsalescentre" ? <span className="active-desktop"></span> : null} </a>}
          {groups?.includes(process.env.REACT_APP_SRP_OKTA_AGENT_GROUP) && <Link className="item" to="/agentportalrequest">Agent Portal Request <br />{location.pathname.includes("/agentportalrequest") ? <span className="active-desktop"></span> : null}  </Link>}
          {groups?.includes(process.env.REACT_APP_SRP_OKTA_SALES_CENTER_GROUP) && <Link className="item" to="/requests">All Requests <br />{location.pathname === "/requests" ? <span className="active-desktop"></span> : null} </Link>}
          </div>

        <div className="nav-right">
          <span className="item">{userName} </span>
          <span className="item" onClick={() => { oktaAuth.signOut() }}>Sign Out</span>
        </div>


      </div>
    </div>
  )
}

export default Header;
