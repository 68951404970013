import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './Header';
import Main from './Main';
import { BrowserRouter, Route } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth, UserClaims, toRelativeUrl } from "@okta/okta-auth-js";

import SrpSalesCenterRequest from './SrpSalesCenterRequest';
import AgentPortalRequest from './AgentPortalRequest';
import Requests from './Requests';
import RequestDetails from './RequestDetails';

const issuer = process.env.REACT_APP_SRP_OKTA_ISSUER;
const clientId = process.env.REACT_APP_SRP_OKTA_CLIENTID;
const redirect = `${window.location.origin}/callback`;
const scopes = process.env.REACT_APP_SRP_OKTA_SCOPES
function App() {

  const [userInfo, setUserInfo] = useState<null | UserClaims>(null);
  const [oktaAuth] = useState(new OktaAuth({
    issuer: issuer,
    clientId: clientId,
    redirectUri: redirect,
    scopes: scopes?.split(",")
  }));

  useEffect(() => {
    const getUserInfo = () => {
      return oktaAuth.token.getUserInfo()
        .then(function (value) {
          setUserInfo(value);
        })
        .catch(function (error) {

        });
    }
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  return (
    <>
      <BrowserRouter>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Header />
          <SecureRoute exact path="/" component={userInfo?.srpGroups.includes(process.env.REACT_APP_SRP_OKTA_SALES_CENTER_GROUP) ? Main : AgentPortalRequest} />
          <SecureRoute exact path="/srpsalescentre" component={SrpSalesCenterRequest} />
          <SecureRoute exact path="/agentportalrequest/:tfa_434/:tfa_493?" component={AgentPortalRequest} />
          <SecureRoute exact path="/requests" component={Requests} />
          <SecureRoute exact path="/requestdetails/:id" component={RequestDetails} />
          <Route exact path="/callback" component={LoginCallback} />
        </Security>

      </BrowserRouter>
    </>
  );
}

export default App;
